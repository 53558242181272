body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter','Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dialogBottom .MuiPaper-root {
  bottom: 0px;
  margin: 0px;
  position: fixed;
  border-radius: 20px 20px 0px 0px;
  width: 100%;
}

.dialogTop .MuiPaper-root {
  top: 0px;
  margin: 0px;
  position: fixed;
  border-radius: 0px 0px 20px 20px;
  width: 100%;
}

.dialogCenter .MuiPaper-root {
  /* top: 0px; */
  margin: 0px;
  position: fixed;
  border-radius: 16px 16px 16px 16px;
  width: 95%;
  max-width: 500px;
}
